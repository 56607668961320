<template>
  <div>
    <Heading heading="h4" content="Lesson Logs" class="mb-4">
      <router-link
        slot="button"
        v-if="roleIsTeacherOrAdmin"
        :to="{
          name: 'privateClassesCreateLog',
          params: { privateType: 'private-lessons', id: $route.params.classId }
        }"
      >
        <el-button type="primary" size="mini">
          <i class="fas fa-plus" />
        </el-button>
      </router-link>
    </Heading>
    <hr />

    <div>
      <el-row>
        <el-button
          @click="
            () => {
              lessonLogMode = 'list';
            }
          "
          :type="lessonLogMode === 'list' ? 'success' : 'default'"
        >
          清單顯示
        </el-button>
        <el-button
          @click="
            () => {
              lessonLogMode = 'calendar';
            }
          "
          :type="lessonLogMode === 'calendar' ? 'success' : 'default'"
        >
          日曆顯示
        </el-button>
      </el-row>
      <el-calendar
        v-if="lessonLogMode === 'calendar'"
        :range="
          getFirstMondayAndLastSunday(
            courseSession.started_on,
            courseSession.ended_on
          )
        "
      >
        <template slot="dateCell" slot-scope="{ data }">
          <div
            style="height: 100%;display: flex;align-items:center;
                  justify-content:center;"
          >
            <div style="text-align: center;" v-if="hasLessonLog(data.day)">
              <template v-if="roleIsTeacherOrAdmin">
                <router-link
                  :to="
                    `/private/private-lessons/${Number(
                      $route.params.classId
                    )}/log/${getLessonLogByDate(data.day).lesson_logs[0].id}`
                  "
                >
                  {{
                    data.day.split("-")[2] === "01"
                      ? data.day
                      : data.day.split("-")[2]
                  }}
                </router-link>
                <router-link
                  v-if="getLessonLogByDate(data.day).has_mailed"
                  :to="
                    `/private/private-lessons/${Number(
                      $route.params.classId
                    )}/editLog/${
                      getLessonLogByDate(data.day).lesson_logs[0].id
                    }`
                  "
                >
                  <el-button class="ml-2" type="primary" size="mini">
                    <i class="fas fa-edit" />
                  </el-button>
                </router-link>
                <router-link
                  v-else
                  :to="
                    `/private/private-lessons/${Number(
                      $route.params.classId
                    )}/editLog/${getLessonLogByDate(data.day).id}`
                  "
                >
                  <el-button type="primary" size="mini">
                    <i class="fas fa-plus" />
                  </el-button>
                </router-link>
                <el-tooltip
                  v-if="
                    isPastLesson(data.day) &&
                      getLessonLogByDate(data.day).has_mailed === 0
                  "
                  class="item"
                  popper-class="tooltipWrong"
                  :content="$t('message.pleaseFinishedTheLessonog')"
                  placement="top"
                >
                  <el-button type="warning" size="mini">
                    <i class="fas fa-exclamation" />
                  </el-button>
                </el-tooltip>
              </template>
              <template v-else-if="isRoleParent() || isRoleStudent()">
                <router-link
                  :to="
                    `/private/private-lessons/${Number(
                      $route.params.classId
                    )}/log/${getLessonLogByDate(data.day).id}/student/${
                      getLessonLogByDate(data.day).student_user_id
                    }`
                  "
                >
                  {{
                    data.day.split("-")[2] === "01"
                      ? data.day
                      : data.day.split("-")[2]
                  }}
                </router-link>
                <span
                  class="attendance"
                  :style="
                    `font-weight: bolder; color: ${
                      attendanceDict[
                        getLessonLogByDate(data.day).attendance_status
                      ].color
                    }`
                  "
                >
                  {{
                    attendanceDict[
                      getLessonLogByDate(data.day).attendance_status
                    ].text
                  }}
                </span>
                <div
                  class="vocabScore"
                  v-if="getLessonLogByDate(data.day).score"
                >
                  {{
                    `Vocab score: ${getLessonLogByDate(data.day).score}/${
                      getLessonLogByDate(data.day).total_score
                    }`
                  }}
                </div>
              </template>
            </div>
            <div style="text-align: center" v-else>
              <span>
                {{
                  data.day.split("-")[2] === "01"
                    ? data.day.slice(5)
                    : data.day.split("-")[2]
                }}
              </span>
            </div>
          </div>
        </template>
      </el-calendar>
      <el-tabs v-else v-model="currenctLessonTab" @tab-click="() => {}">
        <div v-if="isRoleAdmin() && false">
          <el-skeleton :loading="!teachingLogs" animated>
            <template slot="template">
              <el-skeleton-item style="width: 100%; height:300px;" />
            </template>
            <TeachingLogsForAdmin
              :isPrivateLesson="false"
              :classId="Number($route.params.classId)"
              :teachingLogs="teachingLogs"
            />
          </el-skeleton>
        </div>
        <template v-if="isRoleTeacher() || isRoleAdmin()">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="to"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
            @clear="handleDateChange"
            style="margin-bottom: 20px; width:400px;"
          />
          <el-tab-pane
            v-for="teacher in session_class.session_class_teachers"
            :key="teacher.id"
            :name="String(teacher.teacher_user.id)"
          >
            <template slot="label">
              <i class="fas fa-user-graduate" />
              {{ teacher.teacher_user.name }}
            </template>
            <TeachingLogs
              :classId="Number($route.params.classId)"
              :teachingLogs="teachingLogs[teacher.teacher_user.id]"
              :teacherUserId="teacher.teacher_user.id"
              :teacherUserName="teacher.teacher_user.name"
              :showSalary="getShowSalary(teacher.teacher_user.id)"
              @reloadCustomLesson="getLessonLogFromPrivateLessonApi"
            />
          </el-tab-pane>
        </template>
        <template element-loading-spinner="el-icon-loading">
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user" />
              {{ student.name }}
            </template>
            <LessonLogs
              v-loading="!lessonLogs"
              element-loading-spinner="el-icon-loading"
              :classId="Number($route.params.classId)"
              :lessonLogs="lessonLogs[student.id]"
              :enrollSessionId="getEnrollSessionId(student.id)"
              @reloadCustomLesson="getLessonLogFromPrivateLessonApi"
            />
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";
import privateApi from "@/apis/private";
import lessonLogApi from "@/apis/lessonLog";

import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import TeachingLogsForAdmin from "@/views/lessonLog/TeachingLogsForAdmin";
import LessonLogs from "@/views/lessonLog/LessonLogs";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {
    TeachingLogs,
    TeachingLogsForAdmin,
    LessonLogs
  },

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      dateRange: [],
      session_class: null,
      lessonLogMode: "list",
      currenctLessonTab: "teacher",
      courseSession: null,
      teachingLogs: null,
      lessonLogs: null,
      students: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    lessonLogsByRole() {
      if (this.isRoleTeacher() || this.isRoleAdmin()) {
        return this.teachingLogsContent;
      } else if (this.isRoleStudent() || this.isRoleParent()) {
        return this.theStudentLessonLog;
      }
      return [];
    },
    teachingLogsContent() {
      if (this.isRoleAdmin()) {
        return this.teachingLogs[Object.keys(this.teachingLogs)[0]];
      } else {
        return this.teachingLogs[Object.keys(this.teachingLogs)[0]];
      }
    },
    theStudentLessonLog() {
      const userId = this.$store.getters["user/getProfile"].id;
      return this.lessonLogs[userId];
    },
    displayStudentUserIds() {
      if (this.roleIsTeacherOrAdmin) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.$store.getters["user/getProfile"].student_ids
            ? this.$store.getters["user/getProfile"].student_ids
            : [];
        } else {
          return [];
        }
      }
    }
  },
  watch: {
    students() {
      if (
        0 < this.students.length &&
        (this.isRoleParent() || this.isRoleStudent())
      ) {
        this.currenctLessonTab = String(this.students[0].student.id);
        this.currenctReportTab = String(this.students[0].student.id);
      }
    }
  },

  mounted() {
    this.getSessions();
    this.fetchLessonLogs();
  },

  methods: {
    getShowSalary(user_id) {
      if (this.isRoleAdmin()) {
        return true;
      } else {
        if (user_id === this.$store.getters["user/getProfile"].id) {
          return true;
        } else {
          return false;
        }
      }
    },
    getEnrollSessionId(studentId) {
      return this.students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    },
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId
      );
      const courseSessionRes = await courseApi.fetchSession(
        session_class.course_session_id
      );
      this.students = session_class.student_class_tickets;

      if (this.displayStudentUserIds) {
        this.students = this.students.filter(({ student_user_id }) =>
          this.displayStudentUserIds.includes(student_user_id)
        );
      }
      this.courseSession = courseSessionRes.course_session;
      this.session_class = session_class;

      if (this.isRoleTeacher() || this.isRoleAdmin()) {
        const teachers = session_class.session_class_teachers;
        if (teachers.length > 0) {
          this.currenctLessonTab = String(teachers[0].teacher_user.id);
        }
      }
    },
    handleDateChange() {
      this.fetchLessonLogs();
    },
    async fetchLessonLogs() {
      if (this.isRoleAdmin()) {
        console.log("im  admin");
        const teaching_logs = await lessonLogApi.fetchLessonLogByQuery({
          class_id: this.$route.params.classId,
          from:
            this.dateRange && this.dateRange.length > 0
              ? this.dateRange[0]
              : null,
          to:
            this.dateRange && this.dateRange.length > 1
              ? this.dateRange[1]
              : null
        });
        this.teachingLogs = teaching_logs.teaching_logs_group_by_user;
        this.lessonLogs = teaching_logs.lesson_logs_group_by_user;
      } else {
        console.log("im not admin");
        const lesson = await privateApi.getCustomLesson({
          class_id: this.$route.params.classId
        });
        this.teachingLogs = lesson.teaching_logs_group_by_user;
        this.lessonLogs = lesson.lesson_logs_group_by_user;
      }
    },
    getFirstMondayAndLastSunday(start, end) {
      let weekOfday = moment(start, "YYYY-MM-DD").format("E");
      const monday = moment(start)
        .subtract(weekOfday - 1, "days")
        .format("YYYY-MM-DD");
      weekOfday = moment(end, "YYYY-MM-DD").format("E");
      const sunday = moment(end)
        .add(7 - weekOfday, "days")
        .format("YYYY-MM-DD");
      return [monday, sunday];
    },
    isPastLesson(date) {
      return moment(date).isBefore(moment());
    },
    hasLessonLog(date) {
      return this.lessonLogsByRole.find(
        lessonLog => lessonLog.started_at.split(" ")[0] === date
      )
        ? true
        : false;
    },
    getLessonLogByDate(date) {
      return this.lessonLogsByRole.find(
        lessonLog => lessonLog.started_at.split(" ")[0] === date
      );
    },
    async getLessonLogFromPrivateLessonApi() {
      const lesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.classId
      });
      this.setStudentAttendance(lesson);
      this.students = lesson.student_class_tickets;
      this.teacher = lesson.teacher_user;
      if (this.teacher) {
        this.teachingLogs = lesson.teaching_logs_group_by_user;
        this.lessonLogs = lesson.lesson_logs_group_by_user;
      }
    }
  }
};
</script>

<style scoped></style>
